<template>
  <div class="page_container">
    <div class="authors">
      <!-- 作者基本信息 -->
      <div class="author_introduce">
        <div class="headers">
          <img v-if="authorDetail.imageUrl" :src="authorDetail.imageUrl"/>
          <img v-else src="@/assets/image/authorImg.png">
          <button v-if="!isAttention" @click="attention">关注我</button>
          <button v-else style="background: #c9c9c9" @click="notAttention">取消关注</button>
        </div>
        <div class="details">
          <ul>
            <li>
              <span style="visibility: hidden">占位效果</span>
              <div class="nick_name">{{ authorDetail.auName }}</div>
            </li>
            <li>
              <span>浏览量</span>
              <div class="number">{{ authorDetail.scanNum2 }}</div>
            </li>
            <li>
              <span>粉丝数</span>
              <div class="number">{{ authorDetail.fansNums }}</div>
            </li>
            <li>
              <span>获赞数</span>
              <div class="number">{{ authorDetail.thumbsUpNums }}</div>
            </li>
            <li>
              <span>文章数量</span>
              <div class="number">{{ authorDetail.artNums }}</div>
            </li>
          </ul>
          <div class="introduce">{{ authorDetail.introduce }}</div>
        </div>
      </div>

      <!-- 文章列表 -->
      <div>

        <div class="article_list">
          <ul class="lists">
            <li class="list_item" v-for="(item, index) in articleList" :key="index">
              <div class="cover">
                <img :src="item.imageUrl">
              </div>
              <div class="content_container">
                <div class="title" @click="skipDetails(item.atUuid)">{{ item.title }}</div>
                <div class="content" @click="skipDetails(item.atUuid)">{{ item.introduction }}</div>
                <div class="tools">
                  <div class="author">
                    <img v-if="item.iconUrl" :src="item.iconUrl"/>
                    <img v-else src="@/assets/image/authorImg.png">
                    <span class="nick_name">{{ item.authorName }}</span>
                  </div>
                  <ul class="tags">
                    <li v-for="(item, index) in item.labelList.slice(0,4)" :key="index"
                        @click="shipTagList(item.ltName)">{{ item.ltName }}
                    </li>
                  </ul>
                  <span class="time_tip">{{ item.showTime }}</span>
                </div>
              </div>
            </li>
          </ul>

          <div class="more moreBox">
            <span @click.stop="workOfMore">
              <span>查看更多</span>
            </span>
          </div>
        </div>


      </div>


    </div>
    <div class="hot_list">
      <!-- 热门文章 -->
      <hot-article></hot-article>

      <collec-tip></collec-tip>
    </div>

    <popup v-if="isShowPopup" :is-show.sync="isShowPopup"></popup>
  </div>
</template>

<script>
import HotArticle from "../../components/HotArticle/HotArticle";
import CollecTip from "../../components/CollecTip/CollecTip";
import Popup from "../../views/popup/Popup";

export default {
  name: "index",
  components: {CollecTip, HotArticle, Popup},
  data: () => ({
    isShowPopup: false,
    articleList: [],
    authorDetail: {},
    isAttention: true,
    totalPage:1,
    params:{
      auId: -1,
      currentPageSize: 10,
      currentPage: 1
    }
  }),
  created() {
    let params = {
      auId: this.$route.params.auId,
      currentPageSize: 10,
      currentPage: 1
    }

    this.params.auId = this.$route.params.auId;
    this.getList(params)
    this.getAuthorDetail(params)

  },
  methods: {
    workOfMore(){
      //console.log('点击more .... ')
      if(this.params.currentPage >= this.totalPage){
        return;
      }
      this.params.currentPage ++ ;
      this.getList(this.params);
    },
    skipDetails(atUuid) {
      this.$router.push('/article-detail/' + atUuid)
    },
    getList(params) {
      this.axios.get('/sys/author/article', {params}).then(res => {
        //this.articleList = res.page.list;
        let tempList = this.articleList.slice()
        this.articleList = tempList.concat(res.page.list)
        this.totalPage = res.page.totalPage;
        this.params.currentPage = res.page.currPage;
      })
    },
    getAuthorDetail(params) {
      this.axios.get('/sys/author/info', {params}).then(res => {
        this.authorDetail = res.data
        // 读取当前作者是否被关注
        // let auId = this.authorDetail.auId
        // let attentionList = JSON.parse(localStorage.getItem('attentionList'))
        // if (attentionList == null) {
        //   this.isAttention = false
        //   return
        // }
        // this.isAttention = attentionList.some((item) => {
        //   return item == auId
        // })
        this.axios.post('/sys/author/is-attention', {auId: res.data.auId}).then(res => {
          // console.log(res,'ssssssssssss')
          if (res.code == 401) {
            this.isAttention = false
          }
          if (res.code == 0) {
            res.isAttention == 1 ? this.isAttention = true : this.isAttention = false
          }
        })
      })
    },
    // 关注作者
    attention() {
      let auId = this.authorDetail.auId
      this.axios.get('/sys/author/attention', {params: {auId}}).then(res => {
        if (res.code == 401) {
          this.isShowPopup = true
        }
        if (res.code == 0) {
          this.authorDetail.fansNums += 1
          this.isAttention = true
        }
      })

    },
    // 取消关注
    notAttention() {
      let auId = this.authorDetail.auId
      this.authorDetail.fansNums -= 1
      this.isAttention = false
      this.axios.get('/sys/author/attention-cancel', {params: {auId: auId}})
    },
    shipTagList(name) {
      this.$router.push({name: 'TagList', params: {tagType: name}})
    }
  }
}
</script>

<style lang="scss" scoped>
.page_container {
  display: flex;

  .authors {
    flex: 1;
    margin-bottom: 40px;

    .author_introduce {
      width: 100%;
      height: 300px;
      background: #FFFFFF;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 40px 80px 40px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .headers {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        align-items: center;
        margin-right: 40px;

        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }

        button {
          background: #3E35E7;
          color: #FFFFFF;
          outline: none;
          border: none;
          padding: 10px 20px;
          border-radius: 50px;
          cursor: pointer;
        }
      }

      .details {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        ul {
          display: flex;
          font-family: Source Han Sans CN;

          li {
            flex: 1;
            text-align: center;
            line-height: 40px;
            height: 90px;

            .nick_name {
              width: 120px;
              font-size: 18px;
              font-weight: 600;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            span {
              font-size: 14px;
              color: #C6C6C6;
            }

            .number {
              font-size: 30px;
              font-weight: 600;
            }
          }
        }

        .introduce {
          box-sizing: border-box;
          font-size: 14px;
          font-family: Source Han Sans CN;
          letter-spacing: 1px;
          line-height: 24px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden
        }
      }
    }

    .article_list {
      width: 100%;
      background: #FFFFFF;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 40px;
      padding: 40px 20px;

      .lists {
        .list_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid #E4E4E4;
          padding-bottom: 20px;
          cursor: pointer;

          &:not(:first-child) {
            padding-top: 10px;
          }

          &:first-child {
            //padding-bottom: 40px;
          }

          .cover {
            width: 140px;
            height: 100px;
            margin-right: 20px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 4px;
            }
          }

          .content_container {
            flex: 1;
            width: 0;
            position: relative;
            top: -5px;

            .title {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 41px;
              color: #000000;
              //margin-bottom: 10px;
              &:hover {
                color: #3E35E7;
              }
            }

            .content {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              font-size: 14px;
              line-height: 30px;
              color: #000000;
              //min-height: 80px;
            }

            .tools {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;

              .author {
                display: flex;
                align-items: center;

                img {
                  height: 30px;
                  width: 30px;
                  object-fit: cover;
                  border-radius: 50%;
                  margin-right: 5px;
                }

                .nick_name {
                  width: 70px;
                  font-size: 12px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .tags {
                flex: 1;
                display: flex;

                li {
                  height: 24px;
                  line-height: 24px;
                  box-sizing: border-box;
                  text-align: center;
                  background: #ECECEC;
                  font-size: 12px;
                  color: #3E35E7;
                  border-radius: 12px;
                  padding: 0 8px;

                  &:not(:last-child) {
                    margin-right: 10px;
                  }
                }
              }

              @media (min-width: 1280px) {
                .tags {
                  li {
                    &:not(:last-child) {
                      margin-right: 20px;
                    }
                  }
                }
              }

              .show-time {
                color: #999999;
              }
            }
          }
        }

        .list_banner {
          margin: 40px 15px;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .hot_list {
    width: 283px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .moreBox{
    margin: auto;
    border: 1px solid #3e35e7;
    border-radius: 4px;
    width: 230px;
    padding: 12px 0;
    text-align: center;
    color: #3e35e7;
    margin-top: 32px;
    font-family: PingFangSC-Semibold, sans-serif;
    letter-spacing: 1px;
    font-size: 1rem;
  }
  .moreBox:hover{
    background-color: #e1e0f5;
  }

  .more {
    text-align: center;
    color: #3E35E7;
    font-size: 0.9rem;
    cursor: pointer;
  }

}
</style>
